<script setup lang="ts">
import { onMounted, shallowRef, watch } from 'vue'
import Player from 'xgplayer/es/index'
import Mp4Plugin from 'xgplayer-mp4/es/index'

interface PlayerOptions {
  url: string | null
  poster?: string
  fluid?: boolean
  autoplay?: boolean
  aspectRatio?: string
}

const props = withDefaults(defineProps<PlayerOptions>(), {
  autoplay: true,
  fluid: false,
  url: null
})

const player = shallowRef<Player | null>(null)

onMounted(() => {
  player.value = new Player({
    id: 'vs',
    autoplay: true,
    volume: 0.1,
    url: 'https://media.dy09.cn/chengpian/20240809/BJ10335/f.mp4',
    height: window.innerHeight - 300,
    width: window.innerWidth,
    plugins: [Mp4Plugin]
  })
})

watch(() => props.url, (newUrl) => {
  if (newUrl && player.value) {
    playNext(newUrl)
  }
})

function playNext(newUrl: string) {
  console.log('newUrl', newUrl)
  player.value?.playNext({
    autoplay: true,
    plugins: [Mp4Plugin],
    url: newUrl
  })
}
</script>

<template>
  <div class="b-player-container">
    <section id="wrapper">
      <div id="vs" class="b-player" />
    </section>
  </div>
</template>

<style scoped>
.b-player-container {
  position: relative;
  padding-top: 56.25%;
  background: #000;
  height: 0;
  z-index: 0;
}

.b-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
